import React from "react"
import styled from "@emotion/styled"

import {
  Body,
  breakpoints,
  colours,
  Headline1,
  PrimaryLink,
  vertical,
} from "design-kit"
import { WrappedBase } from "../shared-components/Shared/WrappedBase"
import Navbar from "../shared-components/Navbar"

import config from "../../config.json"

const seo = {
  metaTitle: "Not found",
  metaDescription:
    "Habito is the UK's free online mortgage broker, making mortgage worries history. Our smart technology searches the whole market - thousands of mortgage products from over 90 lenders - to get you the perfect mortgage. We've saved thousands of pounds for our 120,000+ customers and we're rated 5*.",
  canonicalUrl: "https://www.habito.com",
  noIndex: false,
  pageName: "404",
}

const FourOhFour: React.FC = () => (
  <WrappedBase
    metaTitle={seo.metaTitle}
    metaDescription={seo.metaDescription}
    canonicalUrl={seo.canonicalUrl}
    noIndex={seo.noIndex}
    pageName={seo.pageName}
    intercom={true}
    config={config}
    head={null}
  >
    <NotFoundPage />
  </WrappedBase>
)

const WidthContainer = styled.div`
  max-width: 640px;
  margin: 0 auto;
  padding: 200px 20px;
`

const Line = styled.div`
  width: 60px;
  background-color: ${colours.destructive.main};
  height: 6px;
  border-radius: 4px;
  border: none;
  margin: ${vertical.xxs} 0;
  text-align: left;
  ${breakpoints.tablet`
    width: 80px;
    height: 8px;
  `}
`

const TitlePadding = styled.div`
  padding-top: 60px;
  padding-bottom: 40px;
`

const Padding = styled.div`
  padding: 20px 0px;
`

const NotFoundPage: React.FC = () => (
  <React.Fragment>
    <Navbar />
    <main className="Page Page--404" id="main-content">
      <WidthContainer>
        <div className="Error404Screen__Text__Container">
          <Line />
          <TitlePadding>
            <Headline1 className="Main-Header">404</Headline1>
          </TitlePadding>
          <Body>
            Sorry the page you requested does not exist. Let's see if we can fix
            that for you&hellip;
          </Body>
          <Padding>
            <PrimaryLink href="/" text="Go to home page" />
          </Padding>
        </div>
        <script>{/* log("viewed_page", { page_name: "404" }); */}</script>
      </WidthContainer>
    </main>
  </React.Fragment>
)

export default FourOhFour
